import React from "react";
import BlogLayout from "../../components/blog-layout";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import BlogPost from "../../components/blog";

function Blog({ data }) {
  const posts = data.allMarkdownRemark.nodes;

  return (
    <BlogLayout>
      <ol style={{ listStyle: `none`, margin: 0 }}>
        {posts.map((post) => {
          const title = post.frontmatter.title || post.fields.slug;

          return <BlogPost post={post} title={title} key={title} />;
        })}
      </ol>
    </BlogLayout>
  );
}

Blog.propTypes = {
  data: PropTypes.object,
};

export default Blog;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          category
        }
      }
    }
  }
`;
